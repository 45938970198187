import React from 'react'

import Page from '../templates/Page'

const kontakt = () => (
  <Page>
    <p>Sie können uns jederzeit mit folgender E-Mail Adresse erreichen.</p>
    <a href="mailto:info@konstruktionsbuero-herga.de">
      info@konstruktionsbuero-herga.de
    </a>
  </Page>
)

export default kontakt
